@import 'swiper/css/bundle';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global variables. */
:root {
  /* If a below css var needs to be deleted or renamed, first search the codebase where that css var may be hardcoded and update accordingly */

  /* Default colors; will be overridden by cms theme settings */
  --background: #FFFFFF;
  --text: #000000;
  --border: #E8E8E8;

  --primary: #E6AD6C;
  --secondary: #F5A284;
  --accent1: #672C45;
  --accent2: #F7E4F3;

  --black: #000000;
  --off-black: #1A1919;
  --dark-gray: #333231;
  --medium-dark-gray: #707070;
  --medium-gray: #666462;
  --gray: #999793;
  --light-gray: #CCC9C4;
  --lighter-gray: #D9D9D9;
  --off-white: #FFFBF5;
  --white: #FFFFFF;

  --sale: #672C45;
  --success: #4D9193;
  --error: #D35032;
  --focus: #522337;
  --disabled: #CCC9C4;
  --violator: #E2E729;

  --gradient-primary: linear-gradient(90deg, #E6AD6C 0%, #F5A284 100%);
  --gradient-a: linear-gradient(0deg, #F5A284 0%, #F7E4F3 100%);
  --gradient-b: radial-gradient(50% 50% at 50% 50%, #E2E729 0%, #F5A284 100%);

  --primary-btn-bg-color: #E6AD6C;
  --primary-btn-border-color: #E6AD6C;
  --primary-btn-text-color: #672C45;
  --primary-btn-hover-bg-color: #DC8D32;
  --primary-btn-hover-border-color: #DC8D32;
  --primary-btn-hover-text-color: #522337;

  --secondary-btn-bg-color: #672C45;
  --secondary-btn-border-color: #672C45;
  --secondary-btn-text-color: #FFFFFF;
  --secondary-btn-hover-bg-color: #522337;
  --secondary-btn-hover-border-color: #522337;
  --secondary-btn-hover-text-color: #FFFFFF;

  --inverse-light-btn-bg-color: transparent;
  --inverse-light-btn-border-color: #FFFFFF;
  --inverse-light-btn-text-color: #FFFFFF;
  --inverse-light-btn-hover-bg-color: #FFFFFF;
  --inverse-light-btn-hover-border-color: #FFFFFF;
  --inverse-light-btn-hover-text-color: #000000;

  --inverse-dark-btn-bg-color: transparent;
  --inverse-dark-btn-border-color: #672C45;
  --inverse-dark-btn-text-color: #672C45;
  --inverse-dark-btn-hover-bg-color: #672C45;
  --inverse-dark-btn-hover-border-color: #672C45;
  --inverse-dark-btn-hover-text-color: #FFFFFF;

  /* Breakpoints */
  --xs: 480px;
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;
  --2xl: 1536px;

  /* Constants */
  --header-height: 4.5rem;
  --promobar-height: 3rem;
  --sidebar-width: 23.5rem;
  --product-image-aspect-ratio: 3 / 4; /* Ensure updating this ratio as needed */
  --content-max-width: 96rem;
}

@layer base {
  * {
    box-sizing: border-box;
  }

  html {
    @apply flex min-h-full w-full flex-col scroll-auto font-helvetica;
  }

  body {
    @apply text-body m-0 flex min-h-[var(--viewport-height)] w-full flex-col bg-background text-text;
  }

  #__next {
    @apply flex min-h-[var(--viewport-height)] flex-col;
  }

  main {
    @apply flex-1
  }

  h1 {
    @apply text-title-h1;
  }

  h2 {
    @apply text-title-h2;
  }

  h3 {
    @apply text-title-h3;
  }

  h4 {
    @apply text-title-h4;
  }

  h5 {
    @apply text-title-h5;
  }

  h6 {
    @apply text-title-h6;
  }

  input {
    background-color: inherit;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  @keyframes scroll {
    0% {
      transform: translate3d(0px, 0px, 0px)
    }
    100% {
      transform: translate3d(-100%, 0px, 0px)
    }
  }

  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@layer components {
  /* text specific */
  .text-title-product {
    @apply
      font-helvetica-condensed
      text-2xl
      font-bold
      md:text-[40px]
      md:leading-[40px]
  }
  .text-title-h1 {
    @apply
      font-feature
      text-4xl
      leading-[48px]
      tracking-[0.36px]
      font-medium
      md:text-[56px]
      md:leading-[69px]
      md:tracking-normal
  }
  .text-alt-h1 {
    @apply
      font-monarch
      text-4xl
      leading-[48px]
      tracking-[0.36px]
      font-normal
      md:text-[56px]
      md:leading-[69px]
      md:tracking-normal
  }
  .text-title-h2 {
    @apply
      font-feature
      text-3xl
      leading-[44px]
      font-medium
      md:text-[40px]
      md:leading-[54px]
  }
  .text-alt-h2 {
    @apply
      font-monarch
      text-3xl
      leading-[44px]
      font-normal
      md:text-[40px]
      md:leading-[54px]
  }
  .text-title-h3 {
    @apply
      font-feature
      text-2xl
      font-medium
      md:text-3xl
      md:leading-[38px]
  }
  .text-title-h4 {
    @apply
      font-feature
      text-xl
      leading-[24px]
      font-medium
      md:text-2xl
  }
  .text-alt-h4 {
    @apply
      font-monarch
      text-xl
      leading-[24px]
      font-normal
      md:text-2xl
  }
  .text-title-h5 {
    @apply
      font-helvetica
      text-base
      leading-[22px]
      font-bold
      md:text-[17px]
      md:leading-[22px]
  }
  .text-title-h6 {
    @apply
      font-helvetica
      text-xs
      leading-[18px]
      font-bold
  }
  .text-body {
    @apply
      font-helvetica
      text-[15px]
      leading-[20px]
      md:text-base
      md:leading-[22px]
      font-normal
  }
  .text-body-sm {
    @apply
      font-helvetica
      text-[11px]
      leading-[18px]
      md:text-[12px]
      font-normal
  }
  .text-body-lg {
    @apply
      font-helvetica
      text-lg
      leading-[22px]
      md:leading-[24px]
      font-normal
  }
  .text-link {
    @apply
      text-body
      leading-[22px]
      font-bold
      uppercase
      underline
      underline-offset-4
  }
  /* "main link" style from figma */
  .text-link-sm {
    @apply
      text-link
      text-xs
      underline-offset-2
  }
  .text-caption {
    @apply
      font-helvetica
      font-medium
      text-[13px]
      leading-[18px]
      uppercase
  }
  .text-label {
    @apply
      font-helvetica
      font-bold
      text-[10px]
      leading-[18px]
      tracking-[0.2px]
      uppercase
  }
  /* no style on figma */
  .text-label-sm {
    @apply
      font-helvetica
      font-medium
      text-2xs
      tracking-wide
      uppercase
  }
  .text-nav-main {
    @apply
      font-helvetica
      font-semibold
      text-base
      leading-[22px]
      capitalize
  }
  .text-nav {
    @apply
      font-helvetica
      font-medium
      text-[15px]
      leading-[22px]
      capitalize
  }
  .text-nav-li {
    @apply
      font-helvetica
      font-normal
      text-[14px]
      leading-[22px]
      capitalize
  }
  /* no style on figma */
  .text-superheading {
    @apply
      font-helvetica
      font-normal
      tracking-wide
      uppercase
      text-xs
      lg:text-sm
  }
  /* button specific */
  .btn-text {
    @apply
      font-helvetica
      font-bold
      uppercase
      text-[15px]
      leading-[20px]
      tracking-[1.2px]
  }
  .btn {
    @apply
      btn-text
      relative
      m-0
      inline-flex
      h-[41px]
      items-center
      justify-center
      overflow-hidden
      whitespace-nowrap
      rounded-lg
      border
      border-solid
      px-5
      py-3
      text-center
      transition-colors
      disabled:cursor-not-allowed
      disabled:border-disabled
      disabled:bg-disabled
      disabled:text-mediumGray
      disabled:hover:border-disabled
      disabled:hover:bg-disabled
      disabled:hover:text-mediumGray
      [&>p]:truncate
  }
  .btn-text-link {
    @apply
      btn-text
      tracking-normal
      relative
      inline
      pb-1
      border-b
      border-[currentColor]
      duration-200
      hover:md:border-[var(--secondary)]
      focus:outline-black
  }
  .btn-text-link-currentColor {
    @apply
      hover:md:border-[currentColor]
  }
  .btn-primary {
    @apply
      btn
      border-[var(--primary-btn-border-color)]
      bg-[var(--primary-btn-bg-color)]
      text-[var(--primary-btn-text-color)]
      md:hover:border-[var(--primary-btn-hover-border-color)]
      md:hover:bg-[var(--primary-btn-hover-bg-color)]
      md:hover:text-[var(--primary-btn-hover-text-color)]
  }
  .btn-secondary {
    @apply
      btn
      border-[var(--secondary-btn-border-color)]
      bg-[var(--secondary-btn-bg-color)]
      text-[var(--secondary-btn-text-color)]
      md:hover:border-[var(--secondary-btn-hover-border-color)]
      md:hover:bg-[var(--secondary-btn-hover-bg-color)]
      md:hover:text-[var(--secondary-btn-hover-text-color)]
  }
  .btn-inverse-light {
    @apply
      btn
      border-[var(--inverse-light-btn-border-color)]
      bg-[var(--inverse-light-btn-bg-color)]
      text-[var(--inverse-light-btn-text-color)]
      md:hover:border-[var(--inverse-light-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-light-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-light-btn-hover-text-color)]
  }
  .btn-inverse-dark {
    @apply
      btn
      border-[var(--inverse-dark-btn-border-color)]
      bg-[var(--inverse-dark-btn-bg-color)]
      text-[var(--inverse-dark-btn-text-color)]
      md:hover:border-[var(--inverse-dark-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-dark-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-dark-btn-hover-text-color)]
  }
  .btn-violator {
    @apply
      btn
      border-[var(--violator)]
      bg-[var(--violator)]
      text-[var(--accent1)]
      md:hover:border-[var(--violator)]
      md:hover:bg-[var(--violator)]
      md:hover:text-[var(--black)]
  }
  .btn-radio {
    @apply
      border-2
      border-black
      rounded-full
      w-5
      h-5
  }
  .btn-radio-is-active {
    @apply
      after:content-['']
      after:bg-white
      after:w-4
      after:h-4
      after:block
      after:rounded-full
      after:border-[3px]
      after:border-black
  }
  /* underline specific */
  .hover-text-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:0%_1px]
      bg-left-bottom
      bg-no-repeat
      transition-[background-size]
      duration-200
      focus:bg-[length:100%_1px]
      md:hover:bg-[length:100%_1px]
  }
  .text-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:100%_1px]
      bg-right-bottom
      bg-no-repeat
      transition-[background-size]
      duration-200
      focus:bg-[length:0%_1px]
      md:hover:bg-[length:0%_1px]
  }
  .text-main-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:100%_2px]
      bg-right-bottom
      bg-no-repeat
      pb-px
      transition-[background-size]
      duration-200
      focus:bg-[length:0%_2px]
      md:hover:bg-[length:0%_2px]
  }
  /* inputs specific */
  .input-text {
    @apply
      h-12
      w-full
      rounded-full
      border
      border-border
      bg-white
      py-2.5
      px-5
      text-base
  }
  .input-label {
    @apply
      block
      pb-1
      pl-5
      font-helvetica
      text-sm
      font-bold
  }
  /* swiper specific */
  .swiper-wrapper-center .swiper-wrapper {
    @apply
      flex
      items-center
  }
  .swiper-offset-gradient-270-left {
    background: linear-gradient(270deg, var(--background) 0%, rgba(255, 255, 255, 0) 100%);
    @apply pointer-events-none absolute top-0 left-[-8px] bottom-0 z-10 h-full w-[5.9375rem] -rotate-180 opacity-[60] lg:w-[8.125rem]
  }
  .swiper-offset-gradient-270-right {
    background: linear-gradient(270deg, var(--background) 0%, rgba(255, 255, 255, 0) 100%);
    @apply pointer-events-none absolute top-0 right-[-8px] bottom-0 z-10 h-full w-[5.9375rem] opacity-[60] lg:w-[8.125rem]
  }
  .active-bullet-black .swiper-pagination-bullet-active {
    @apply
      bg-black
  }
}

@layer utilities {
  /* hide scrollbar */
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  /* horizontal padding */
  .px-contained {
    @apply
      px-4
      md:px-8
      xl:px-12
  }
  /* vertical padding */
  .py-contained {
    @apply
      py-8
      md:py-10
      xl:py-12
  }
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }

}